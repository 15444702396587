import React, { useState } from 'react';
import ThemedSpinner from '../themed/ThemedSpinner';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function AddManager({ addManager, addingManager }) {
    const [managerName, setManagerName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [managerRole, setManagerRole] = useState(1);
    const [managerSendWelcome, setManagerSendWelcome] = useState(false);
    const [managerNameError, setManagerNameError] = useState('');
    const [managerEmailError, setManagerEmailError] = useState('');
    const [error, setError] = useState('');

    const changeEmail = (e) => {
        setManagerEmail(e.target.value);
        setError('');
        e.target.value.length < 4
            ? setManagerEmailError('Email needs to be at least 4 characters.')
            : setManagerEmailError('');
    };
    const changeName = (e) => {
        setManagerName(e.target.value);
        setError('');
        e.target.value.length < 4
            ? setManagerNameError('Name needs to be at least 4 characters.')
            : setManagerNameError('');
    };

    const checkSendWelcome = () => {
        setManagerSendWelcome(!managerSendWelcome);
    };

    const saveManager = () => {
        addManager(managerName, managerEmail, managerRole, managerSendWelcome);
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Add new manager
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="managerEmail"
                    label="E-mail"
                    value={managerEmail}
                    onChange={(e) => changeEmail(e)}
                    type="email"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="managerName"
                    label="Name"
                    value={managerName}
                    onChange={(e) => changeName(e)}
                    type="text"
                />
            </Grid>
            <Grid item>
                <Select
                    variant="filled"
                    name="managerRole"
                    label="Role"
                    value={managerRole}
                    onChange={(e) => {
                        setManagerRole(e.target.value);
                    }}
                >
                    <MenuItem value="1">Admin</MenuItem>
                    <MenuItem value="2">Viewer</MenuItem>
                </Select>
            </Grid>
            <Grid item>
                {addingManager ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveManager}
                        startIcon={<AddIcon />}
                        type="submit"
                    >
                        Add
                    </Button>
                )}
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={managerSendWelcome}
                            onChange={checkSendWelcome}
                            name="sendWelcome"
                        />
                    }
                    label="Send a welcome email"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography color="secondary">
                    {error || managerEmailError || managerNameError}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default AddManager;
