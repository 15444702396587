import Auth from './AuthService';
import Users from './UsersService';
import Builds from './BuildsService';
import Subjects from './SubjectsService';
import Companies from './CompaniesService';
import Campaigns from './CampaignsService';
import Categories from './CategoriesService';
import Templates from './TemplatesService';
import Questions from './QuestionsService';
import Automations from './AutomationsService';
import System from './SystemService';
import Packages from './PackagesService';
import Mails from './MailsService';
import Freemiums from './FreemiumsService';
import Resources from './ResourcesService';
import Branding from './BrandingService';
import Partners from './PartnersService';
import Notifications from './NotificationsService';
import Stats from './StatsService';
import Videos from './VideosService';
import LanguageService from './LanguageService';
import ACLService from './acl';
import SubscriptionsService from './SubscriptionsService';
import MaterialsService from './MaterialService';
import ScheduledTasksService from './ScheduledTasksService';
import ServiceTasksService from './ServiceTasksService';
import TagsService from './TagsService';
import ThreatAreas from './ThreatAreaService';
import IntegrationsService from './IntegrationsService';

export default {
    Auth: new Auth(),
    Users: new Users(),
    Builds: new Builds(),
    Subjects: new Subjects(),
    Companies: new Companies(),
    Campaigns: new Campaigns(),
    Categories: new Categories(),
    Templates: new Templates(),
    Questions: new Questions(),
    Automations: new Automations(),
    Packages: new Packages(),
    System: new System(),
    Mails: new Mails(),
    Freemiums: new Freemiums(),
    Resources: new Resources(),
    Branding: new Branding(),
    Partners: new Partners(),
    Videos: new Videos(),
    Languages: new LanguageService(),
    Notifications: new Notifications(),
    Stats: new Stats(),
    ACL: new ACLService(),
    Subscriptions: new SubscriptionsService(),
    Materials: new MaterialsService(),
    ScheduledTasks: new ScheduledTasksService(),
    ServiceTasks: new ServiceTasksService(),
    Tags: new TagsService(),
    ThreatAreas: new ThreatAreas(),
    Integrations: new IntegrationsService(),
};
