import React, { Fragment } from 'react';
import {
    useTable,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from 'react-table';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import './SimpleTable.scss';

function SimpleTable({
    columns,
    data,
    needsFilter,
    pageSize: pageSizeOption = 50,
}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: pageSizeOption },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    return (
        <Fragment>
            {needsFilter && (
                <Box mb={2}>
                    <TextField
                        type="text"
                        label="Search"
                        color="primary"
                        variant="outlined"
                        value={globalFilter || ''}
                        onChange={(e) => {
                            setGlobalFilter(e.target.value || undefined);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
            <Grid container direction="row" justify="flex-start" align="center">
                <Grid item xs={12}>
                    <MuiTable {...getTableProps()}>
                        <TableHead>
                            {headerGroups.map((headerGroup, i) => (
                                <TableRow
                                    key={i}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column, j) => (
                                        <TableCell
                                            key={j}
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                            style={{
                                                minWidth: column.minWidth,
                                                top: 57,
                                            }}
                                        >
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? '🔽 '
                                                    : '🔼 '
                                                : ''}
                                            {column.Header !== ' '
                                                ? column.render('Header')
                                                : null}
                                            {column.Filter && (
                                                <div>
                                                    {column.render('Filter')}
                                                </div>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <TableRow {...row.getRowProps()} key={i}>
                                        {row.cells.map((cell, j) => {
                                            return (
                                                <TableCell
                                                    key={j}
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </MuiTable>
                </Grid>
                <Grid item xs={12}>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        count={data.length}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        onChangePage={(e, newPage) => {
                            gotoPage(newPage);
                        }}
                        onChangeRowsPerPage={(e) => {
                            setPageSize(Number(+e.target.value));
                        }}
                        ActionsComponent={() => (
                            <Fragment>
                                <IconButton
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                    aria-label="first page"
                                >
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    aria-label="previous page"
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    aria-label="next page"
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                                <IconButton
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                    aria-label="last page"
                                >
                                    <LastPageIcon />
                                </IconButton>
                            </Fragment>
                        )}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default SimpleTable;
