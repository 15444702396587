import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { formatDate } from '../../utils/helpers';
import services from '../../services';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Table from '../../components/super/Table';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

function CompanyColumnFilter({ column: { filterValue, setFilter } }) {
    return (
        <FormControl size="small">
            <TextField
                type="text"
                label="Company"
                variant="filled"
                value={filterValue || ''}
                size="small"
                placeholder="Company ID"
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
            />
        </FormControl>
    );
}

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function CompaniesList() {
    let { partnerId } = useParams();
    // We'll start our table without any data
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);
    const classes = useStyles();

    let now = new Date();

    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, filters }) => {
            // This will get called when the table needs new data
            // You could fetch your data from literally anywhere,
            // even a server. But for this example, we'll just fake it.

            // Give this fetch an ID
            const fetchId = ++fetchIdRef.current;
            setLoading(true);
            let filtersObject = {};
            filters.forEach((x) => {
                filtersObject[x.id] = x.value;
            });

            let result;
            if (partnerId) {
                result = await services.Partners.companiesService(
                    partnerId
                ).list({
                    p: pageIndex,
                    n: pageSize,
                    s: null,
                    f: null,
                    fetchId,
                    ...filtersObject,
                });
            } else {
                result = await services.Companies.list({
                    p: pageIndex,
                    n: pageSize,
                    s: null,
                    f: null,
                    fetchId,
                    ...filtersObject,
                });
            }

            if (fetchId === fetchIdRef.current) {
                setData(result.rows);

                setPageCount(result.pages);

                setLoading(false);
            }
        },
        []
    );

    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                id: 'companyName',
                accessor: (x) => (
                    <Link to={`/companies/${x.id}`}>
                        {x.name !== null ? x.name : 'null'}
                    </Link>
                ),
                Filter: CompanyColumnFilter,
            },
            {
                Header: 'Created',
                accessor: (x) => formatDate(x.created),
            },
            {
                Header: 'Active until',
                accessor: (x) => (
                    <span
                        style={{ color: new Date(x.valid_to) < now && 'red' }}
                    >
                        {formatDate(x.valid_to)}
                    </span>
                ),
            },
            {
                Header: 'Users',
                accessor: 'user_count',
            },
            {
                Header: 'Trainings',
                accessor: 'training_count',
            },
            {
                Header: 'Subscription',
                accessor: 'subscriptionCode',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Companies</Typography>
                </Box>
            </Grid>
            <Tooltip title="Create company" aria-label="add" placement="left">
                <Fab
                    color="primary"
                    href="/companies/create"
                    className={classes.fab}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Grid item xs={12}>
                <Table
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                />
            </Grid>
        </Grid>
    );
}

export default observer(CompaniesList);
