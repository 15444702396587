import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SimpleTable from '../../components/super/SimpleTable';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function PartnersList() {
    const { superStore } = useStores();
    const { loadingPartners, partners } = superStore;
    const classes = useStyles();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.loadPartners();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                Cell: (x) => (
                    <Link to={`/partners/${x.row.original.id}`}>{x.value}</Link>
                ),
                accessor: 'name',
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Managers',
                accessor: (x) =>
                    x.managers.map((manager) => (
                        <Fragment key={manager}>
                            {manager}
                            <br />
                        </Fragment>
                    )),
            },
            {
                Header: 'LMS domain',
                accessor: 'lms_domain',
                /*(x) =>
                    x.lms_domain.map((domain) => (
                        <Fragment  key={domain}>
                            {domain}
                            <br />
                        </Fragment>
                    )),*/
            },
            {
                Header: 'MY domain',
                accessor: 'my_domain',
                /*(x) =>
                    x.my_domain.map((domain) => (
                        <Fragment  key={domain}>
                            {domain}
                            <br />
                        </Fragment>
                    )),*/
            },
            {
                Header: 'Mail domain',
                accessor: 'mail_domain',
                /*(x) =>
                    x.mailgun_domain.map((domain) => (
                        <Fragment key={domain}>
                            {domain}
                            <br />
                        </Fragment>
                    )),*/
            },
            {
                Header: 'Companies',
                accessor: 'company_count',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Partners</Typography>
                </Box>
            </Grid>
            {loadingPartners ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create partner"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/partners/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    {partners && partners.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={partners}
                                needsFilter={true}
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(PartnersList);
