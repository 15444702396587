import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import ErrorMessage from '../../components/errormessage';
import { formatDate } from '../../utils/helpers';
import SettingsTable from '../../components/super/settings';
import AddSetting from '../../components/super/add-setting';
import AddManager from '../../components/super/add-manager';
import ContentGroupAccess from '../../components/super/content-group-access';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SimpleTable from '../../components/super/SimpleTable';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

function PartnerView() {
    let { partnerId } = useParams();
    const { superStore, commonStore } = useStores();
    const {
        loadingPartner,
        aclGroups,
        aclRulesForPrincipal,
        creatingAclLine,
        removingAclLines,
        loadingAclRulesForPrincipal,
        addingPartnerManager,
        removingPartnerManagers,
        removingPartnerSettings,
        uploadingPartnerLogo,
        uploadingPartnerFavicon,
        togglingPartner,
        addingPartnerSetting,
        editingPartner: partner,
        error,
    } = superStore;
    let match = useRouteMatch();

    const billingManager =
        partner && partner.managers && partner.managers.find((x) => x.billing);

    const loadPartner = async () => {
        await superStore.loadPartner(partnerId);
    };

    const loadData = async () => {
        await loadPartner();
        await superStore.loadAclGroups();
    };

    const loadAclRulesForPartner = async () => {
        if (partner && partner.unique_id) {
            await superStore.loadAclRulesForPrincipal(partner.unique_id);
        }
    };

    const deleteAclRule = async (id) => {
        await superStore.removeAclLine(id);
        await loadAclRulesForPartner();
    };

    const createAclRule = async (principal_unique_id, unique_id, rule = 1) => {
        await superStore.createAclLine({
            rule,
            principal_unique_id,
            object_unique_id: unique_id,
        });
        await loadAclRulesForPartner();
    };

    useEffect(() => {
        loadData();
    }, [partnerId]);

    useEffect(() => {
        loadAclRulesForPartner();
    }, [partner]);

    const addSetting = (name, value) => {
        const data = { name, value };
        superStore.addPartnerSetting(partnerId, data);
    };

    const onChangeSetting = (id, value) => {
        superStore.updatePartnerSetting(partnerId, id, value);
    };

    const removeSetting = (settingId) => {
        superStore.removePartnerSetting(partnerId, settingId);
    };

    const addManager = (name, email) => {
        superStore.addPartnerManager(partnerId, { name, email });
    };

    const onChangeBillingPerson = (user_id) => {
        superStore.setBillingPerson(partnerId, { user_id });
    };

    const removeManager = (userId) => {
        superStore.removePartnerManager(partnerId, userId);
    };

    const deactivate = () => {
        commonStore.showConfirm(
            'By doing that, partner will be DISABLED and Chargebee subscriptions will be CANCELLED',
            'Deactivate',
            'Deactivate',
            async () => {
                await superStore.togglePartner(partnerId, false);
                await loadPartner();
            }
        );
    };

    const activate = () => {
        commonStore.showConfirm(
            'By doing that, partner will be ENABLED and Chargebee subscriptions will be RECREATED. Immediate invoice can be also issued',
            'Activate',
            'Activate',
            async () => {
                await superStore.togglePartner(partnerId, true);
                await loadPartner();
            }
        );
    };

    const [uploadError, setUploadError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const handleLogoSelect = async (evt) => {
        let error = null;

        evt.stopPropagation();
        evt.preventDefault();

        const files = evt.target.files || evt.dataTransfer.files;
        const image = files[0];

        setUploading(true);

        // Error handling
        if (image.type !== 'image/png') {
            error = `'${image.type}' is not a supported format`;
        } else if (image.size > 80000) {
            error = `'${image.name}' is too large, please pick a smaller file`;
        }

        const formData = new FormData();
        formData.append('image', image);

        if (error) {
            setUploadError(error);
        } else {
            setUploadError(null);
            await superStore.uploadPartnerLogo(partnerId, formData);
        }
    };

    const handleFavIconSelect = async (evt) => {
        let error = null;

        evt.stopPropagation();
        evt.preventDefault();

        const files = evt.target.files || evt.dataTransfer.files;
        const image = files[0];

        setUploading(true);

        let accepted = [
            'image/png',
            'image/x-icon',
            'image/vnd.microsoft.icon',
        ];
        // Error handling
        if (!accepted.includes(image.type)) {
            error = `'${image.type}' is not a supported format`;
        } else if (image.size > 80000) {
            error = `'${image.name}' is too large, please pick a smaller file`;
        }

        const formData = new FormData();
        formData.append('image', image);

        if (error) {
            setUploadError(error);
        } else {
            setUploadError(null);
            await superStore.uploadPartnerFavicon(partnerId, formData);
        }
    };

    const logoFileRef = useRef();
    const faviconFileRef = useRef();

    const chooseFile = (fileRef) => {
        fileRef.current.click();
    };

    const handleDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'removeManager',
                accessor: (x) =>
                    removingPartnerManagers.includes(x.email) ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            onClick={() => removeManager(x.id)}
                            color="secondary"
                            variant="contained"
                        >
                            X
                        </Button>
                    ),
            },
        ],
        []
    );

    const logo = useMemo(() => {
        if (partner && partner.settings) {
            let s = partner.settings.find((x) => x.name === 'ui.logo');
            if (s) return s.value;
        }
    }, [partner]);

    const favicon = useMemo(() => {
        if (partner && partner.settings) {
            let s = partner.settings.find((x) => x.name === 'ui.favicon');
            if (s) return s.value;
        }
    }, [partner]);

    return (loadingPartner && !partner) || togglingPartner ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            {partner && (
                <Fragment>
                    {(error || uploadError) && (
                        <Grid item xs={12}>
                            <ErrorMessage
                                error={error || uploadError}
                                errorType="error"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box mt={4}>
                            <Typography variant="h2" gutterBottom>
                                Partner{partner && `: ${partner.name}`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={`${enforceSlash(match.url)}edit`}
                            startIcon={<EditIcon />}
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="default"
                            href={`/partners/${partner.id}/companies/`}
                        >
                            View Companies
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color={partner.enabled ? 'secondary' : 'primary'}
                            onClick={partner.enabled ? deactivate : activate}
                        >
                            {partner.enabled ? 'Deactivate' : 'Activate'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            color={partner.enabled ? 'primary' : 'secondary'}
                        >
                            <strong>Status: </strong>{' '}
                            {partner.enabled ? 'Active' : 'Disabled'}
                        </Typography>
                    </Grid>
                    {partner.enabled ? (
                        <Fragment>
                            {partner.subscription && (
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>
                                            Chargebee Subscription:{' '}
                                        </strong>{' '}
                                        {partner.subscription.id}
                                    </Typography>
                                </Grid>
                            )}
                            {partner.subscription &&
                                !partner.subscription.custom && (
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            <strong>
                                                Chargebee Customer:{' '}
                                            </strong>{' '}
                                            {partner.subscription.customer_id}
                                        </Typography>
                                    </Grid>
                                )}
                            {partner.subscription &&
                                !partner.subscription.custom && (
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {partner.subscription.haveValidCard
                                                ? 'Billing info is OK'
                                                : 'No Billing info'}
                                        </Typography>
                                    </Grid>
                                )}
                            {partner.subscription && partner.enabled && (
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Next Payment: </strong>{' '}
                                        {moment
                                            .unix(
                                                partner.subscription
                                                    .next_billing_at
                                            )
                                            .format('LLL')}
                                        ,{' '}
                                        {(
                                            Math.max(
                                                partner.subscription
                                                    .plan_amount +
                                                    partner.subscription
                                                        .unbilled_charges -
                                                    partner.subscription
                                                        .promotional_credits -
                                                    partner.subscription
                                                        .refundable_credits,
                                                0
                                            ) / 100
                                        ).toFixed(2) + ' USD'}
                                    </Typography>
                                </Grid>
                            )}
                            {partner.subscription &&
                                !partner.subscription.custom && (
                                    <Fragment>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                <strong>
                                                    Plan amount (users):{' '}
                                                </strong>
                                                {
                                                    partner.subscription
                                                        .plan_quantity
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                <strong>
                                                    Plan amount (USD):{' '}
                                                </strong>
                                                {(
                                                    partner.subscription
                                                        .plan_amount / 100
                                                ).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                <strong>
                                                    Unbilled Charges:{' '}
                                                </strong>
                                                {(
                                                    partner.subscription
                                                        .unbilled_charges / 100
                                                ).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                <strong>
                                                    Promotional Credits:{' '}
                                                </strong>
                                                {(
                                                    partner.subscription
                                                        .promotional_credits /
                                                    100
                                                ).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                <strong>
                                                    Refundable Credits:{' '}
                                                </strong>
                                                {(
                                                    partner.subscription
                                                        .refundable_credits /
                                                    100
                                                ).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                )}
                        </Fragment>
                    ) : (
                        ''
                    )}
                    <Grid item xs={12} container>
                        <Grid item xs={12} sm={6}>
                            {logo && (
                                <img src={logo} style={{ maxWidth: '200px' }} />
                            )}
                            <div className="company-logo-drop-zone">
                                <div
                                    className="d-flex flex-center flex-fill flex-column"
                                    style={{ height: '220px' }}
                                    onDragOver={handleDragOver}
                                    onDrop={handleLogoSelect}
                                >
                                    <img
                                        src="/static/img/drop-icon.svg"
                                        alt=""
                                    />
                                    {uploadingPartnerLogo || uploading ? (
                                        <div> Uploading...</div>
                                    ) : (
                                        <div style={{ textAlign: 'center' }}>
                                            <div id="drop_zone">
                                                Drag and drop
                                            </div>
                                            <div className="drop_zone_description">
                                                {' '}
                                                or{' '}
                                                <Button
                                                    color={'secondary'}
                                                    onClick={(e) => {
                                                        chooseFile(
                                                            logoFileRef,
                                                            e
                                                        );
                                                    }}
                                                >
                                                    browse
                                                </Button>{' '}
                                                for an image file
                                            </div>
                                        </div>
                                    )}
                                    <input
                                        className="hidden"
                                        type="file"
                                        multiple={false}
                                        accept="image/png"
                                        onChange={handleLogoSelect}
                                        ref={logoFileRef}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {favicon && (
                                <img
                                    src={favicon}
                                    style={{ maxWidth: '200px' }}
                                />
                            )}
                            <div className="company-logo-drop-zone">
                                <div
                                    className="d-flex flex-center flex-fill flex-column"
                                    style={{ height: '220px' }}
                                    onDragOver={handleDragOver}
                                    onDrop={handleFavIconSelect}
                                >
                                    <img
                                        src="/static/img/drop-icon.svg"
                                        alt=""
                                    />
                                    {uploadingPartnerFavicon ? (
                                        <div> Uploading...</div>
                                    ) : (
                                        <div style={{ textAlign: 'center' }}>
                                            <div id="drop_zone">
                                                Drag and drop
                                            </div>
                                            <div className="drop_zone_description">
                                                {' '}
                                                or{' '}
                                                <Button
                                                    color={'secondary'}
                                                    onClick={(e) => {
                                                        chooseFile(
                                                            faviconFileRef,
                                                            e
                                                        );
                                                    }}
                                                >
                                                    browse
                                                </Button>{' '}
                                                for an image file
                                            </div>
                                        </div>
                                    )}
                                    <input
                                        className="hidden"
                                        type="file"
                                        multiple={false}
                                        accept="image/png,image/x-icon,image/vnd.microsoft.icon"
                                        onChange={handleFavIconSelect}
                                        ref={faviconFileRef}
                                    />
                                </div>
                            </div>
                            <div className="company-logo-drop-zone-text">
                                The image file should be in PNG, up to 640 x 264
                                pixels and no bigger than 80KB
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AddSetting
                            saveSetting={addSetting}
                            addingSetting={addingPartnerSetting}
                        />
                    </Grid>
                    {partner.settings && partner.settings.length > 0 && (
                        <Grid item xs={10} md={6}>
                            <SettingsTable
                                settings={partner.settings}
                                removeSetting={removeSetting}
                                removingSettings={removingPartnerSettings}
                                onChangeSettingValue={onChangeSetting}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Super Admins
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AddManager
                            addingManager={addingPartnerManager}
                            addManager={addManager}
                        />
                    </Grid>
                    {partner.managers && partner.managers.length > 0 && (
                        <Fragment>
                            <Grid item xs={8}>
                                <SimpleTable
                                    columns={columns}
                                    data={partner.managers}
                                    needsFilter={partner.managers.length > 10}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box mb={4}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>Billing Contact</InputLabel>
                                        <Select
                                            variant="filled"
                                            name="billingManager"
                                            value={
                                                billingManager &&
                                                billingManager.user_id
                                            }
                                            onChange={(e) =>
                                                onChangeBillingPerson(
                                                    Number(e.target.value)
                                                )
                                            }
                                        >
                                            {partner &&
                                                partner.managers &&
                                                partner.managers.map(
                                                    (manager) => (
                                                        <MenuItem
                                                            key={
                                                                manager.user_id
                                                            }
                                                            value={
                                                                manager.user_id
                                                            }
                                                        >
                                                            {manager.name} &lt;
                                                            {manager.email}&gt;
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Fragment>
                    )}
                    {partner && aclGroups.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    Content Group Access
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ContentGroupAccess
                                    aclGroups={aclGroups}
                                    aclRules={aclRulesForPrincipal}
                                    principalUniqueId={partner.unique_id}
                                    addToGroup={createAclRule}
                                    removeFromGroup={deleteAclRule}
                                    showLoader={
                                        creatingAclLine ||
                                        removingAclLines.length > 0 ||
                                        loadingAclRulesForPrincipal
                                    }
                                />
                            </Grid>
                        </>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(PartnerView);
