import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import ACLEditor from './ACLEditor';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SimpleTable from '../../components/super/SimpleTable';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { enforceSlash } from '../../utils/helpers';

function Template() {
    const { superStore } = useStores();
    let { templateId } = useParams();
    const {
        loadingTemplate,
        editingTemplate,
        templatesError,
        editingTemplateSubjects,
    } = superStore;

    const match = useRouteMatch();

    const loadData = async () => {
        await superStore.loadTemplate(templateId);
        await superStore.loadTemplateSubjects(templateId);
    };

    useEffect(() => {
        loadData();
    }, [templateId]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'subject_id',
                Cell: (x) => (
                    <Link to={`/content/subjects/${x.value}`}>{x.value}</Link>
                ),
            },
            {
                Header: 'Title',
                accessor: 'title',
                Cell: (x) => (
                    <Link to={`/content/subjects/${x.row.original.subject_id}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Episode number',
                accessor: 'episode_number',
            },
        ],
        []
    );

    return loadingTemplate || !editingTemplate ? (
        <ThemedSpinner />
    ) : (
        editingTemplate && (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2" gutterBottom>
                            Title: {editingTemplate.title}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        <strong>Description:</strong> {editingTemplate.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        <strong>Tip:</strong> {editingTemplate.tip}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Subjects</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        href={`${enforceSlash(match.url)}subjects`}
                        startIcon={<EditIcon />}
                    >
                        Edit Subjects
                    </Button>
                </Grid>
                {editingTemplateSubjects &&
                editingTemplateSubjects.length > 0 ? (
                    <Grid item xs={8}>
                        <SimpleTable
                            columns={columns}
                            data={editingTemplateSubjects}
                            needsFilter={editingTemplateSubjects.length > 5}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={8}>
                        <Typography>No subjects found :(</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Access control
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ACLEditor
                        acl={editingTemplate.acl}
                        unique_id={editingTemplate.unique_id}
                    />
                </Grid>
                {templatesError && (
                    <Grid item xs={12}>
                        <ErrorMessage error={templatesError} />
                    </Grid>
                )}
            </Grid>
        )
    );
}

export default observer(Template);
