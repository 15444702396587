import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/NotificationsActive';
import { makeStyles } from '@material-ui/core';
import moment from 'moment/moment';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Errormessage from '../../components/errormessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const Row = observer(({ data, index, trainingID }) => {
    const classes = useStyles();
    const { commonStore, scheduleStore } = useStores();

    const { cleaningNotifications } = scheduleStore;

    const handleStatusChange = (data) => {
        scheduleStore.updateEvent(index, {
            dirty: true,
            status: data.status === 2 ? 0 : 2,
        });
    };

    const handleSendChange = (e) => {
        scheduleStore.updateEvent(index, { dirty: true, send: e.target.value });
    };

    const revertChanges = () => {
        scheduleStore.revertEvent(index);
    };

    const saveChanges = () => {
        scheduleStore.saveChanges(index);
    };

    const cleanNotifications = () => {
        commonStore.showConfirm(
            <>
                Are you sure you want to clean all notifications attached to
                this event?
                <br />
                <strong>
                    THINK TWICE ON PRODUCTION ENV. This will cause resend
                    notifications to all recipients.
                </strong>
            </>,
            'Clean',
            'Clean notifications',
            async () => {
                const result = await scheduleStore.cleanNotifications(index);
                if (result) {
                    commonStore.showMessage('Notifications cleaned');
                    scheduleStore.loadSchedule(trainingID);
                }
            }
        );
    };

    return (
        <tr>
            <td>{data.id}</td>
            <td>{data.type === 2 ? <ScheduleIcon /> : <EventIcon />}</td>

            <td>
                <Checkbox
                    checked={data.status === 2}
                    onChange={() => {
                        handleStatusChange(data);
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </td>
            <td>
                <TextField
                    id="datetime-local"
                    label="Send date/time"
                    type="datetime-local"
                    value={moment(data.send).format('YYYY-MM-DDTHH:MM')}
                    className={classes.textField}
                    onChange={handleSendChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </td>
            <td>
                {data.dirty ? (
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        onClick={saveChanges}
                    >
                        Save
                    </Button>
                ) : null}
                {data.dirty ? (
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color={'secondary'}
                        onClick={revertChanges}
                    >
                        Revert
                    </Button>
                ) : null}
            </td>
            <td>
                {data.notificationsCount > 0 && (
                    <>
                        {' '}
                        {data.notificationsCount} attached notifications.{' '}
                        {cleaningNotifications.includes(data.id) ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                color={'secondary'}
                                onClick={cleanNotifications}
                            >
                                Clean
                            </Button>
                        )}
                    </>
                )}
            </td>
        </tr>
    );
});

function Schedule() {
    const [trainingID, setTrainingID] = useState('');
    const { scheduleStore } = useStores();
    const {
        loadingSchedule,
        schedule,
        error,
        runningSchedule,
        runningReminders,
    } = scheduleStore;

    const handleLoad = () => {
        scheduleStore.loadSchedule(trainingID);
    };

    const handleChange = (e) => {
        setTrainingID(e.target.value);
    };

    const runSchedule = () => {
        scheduleStore.runSchedule(trainingID);
    };
    const runReminders = () => {
        scheduleStore.runReminders(trainingID);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Errormessage error={error} errorType={'error'} />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={trainingID}
                    fullWidth
                    type={'number'}
                    placeholder={'Training ID'}
                    onChange={handleChange}
                ></TextField>
            </Grid>
            <Grid item xs={2}>
                {loadingSchedule ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleLoad}
                    >
                        Load
                    </Button>
                )}
            </Grid>

            {schedule && (
                <>
                    <Grid item xs={12}>
                        <Typography variant={'h5'}>
                            {schedule.name} - version {schedule.version}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {runningSchedule ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                onClick={runSchedule}
                            >
                                Run &quot;Shedule&quot; sending
                            </Button>
                        )}
                        {runningReminders ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                onClick={runReminders}
                            >
                                Run &quot;Reminders&quot; sending
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ padding: '20px' }}>Id:</td>
                                    <td style={{ padding: '20px' }}>Type:</td>
                                    <td style={{ padding: '20px' }}>Status:</td>
                                    <td style={{ padding: '20px' }}>Send:</td>
                                    <td style={{ padding: '20px' }}></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {schedule.events.map((e, i) => (
                                    <Row
                                        key={e.id}
                                        data={e}
                                        index={i}
                                        trainingID={trainingID}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default observer(Schedule);
