import React, { useEffect, useState } from 'react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import HtmlField from '../../components/input/HtmlField';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

function SubjectLanguage({ subject, question, content }) {
    const { superStore } = useStores();
    const [value, setValue] = useState('');
    const [translationTitle, setTranslationTitle] = useState('');
    const [translationDescription, setTranslationDescription] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [questionAnswer, setQuestionAnswer] = useState('');
    const [questionWrong1, setQuestionWrong1] = useState('');
    const [questionWrong2, setQuestionWrong2] = useState('');
    const [questionWrong3, setQuestionWrong3] = useState('');
    const [translationEdited, setTranslationEdited] = useState(false);
    const [questionEdited, setQuestionEdited] = useState(false);
    const [contentEdited, setContentEdited] = useState(false);

    useEffect(() => {
        setTranslationTitle(subject ? subject.title : '');
        setTranslationDescription(subject ? subject.description : '');
        setQuestionText(question ? question.question : '');
        setQuestionAnswer(question ? question.correct_answer : '');
        setQuestionWrong1(question ? question.wrong_answer1 : '');
        setQuestionWrong2(question ? question.wrong_answer2 : '');
        setQuestionWrong3(question ? question.wrong_answer3 : '');
        setTranslationEdited(false);
        setQuestionEdited(false);
        setContentEdited(false);
    }, [subject, question]);

    const onChangeTranslationTitle = (e) => {
        setTranslationEdited(true);
        setTranslationTitle(e.target.value);
    };

    const onChangeTranslationDescription = (e) => {
        setTranslationEdited(true);
        setTranslationDescription(e.target.value);
    };

    const onChangeQuestionText = (e) => {
        setQuestionEdited(true);
        setQuestionText(e.target.value);
    };

    const onChangeQuestionAnswer = (e) => {
        setQuestionEdited(true);
        setQuestionAnswer(e.target.value);
    };

    const onChangeQuestionWrong1 = (e) => {
        setQuestionEdited(true);
        setQuestionWrong1(e.target.value);
    };
    const onChangeQuestionWrong2 = (e) => {
        setQuestionEdited(true);
        setQuestionWrong2(e.target.value);
    };
    const onChangeQuestionWrong3 = (e) => {
        setQuestionEdited(true);
        setQuestionWrong3(e.target.value);
    };

    const onChange = (value) => {
        setContentEdited(true);
        setValue(value);
    };

    const onSave = async () => {
        if (translationEdited) {
            await superStore.editSubjectLanguageTranslation(
                subject.subject_id,
                subject.language,
                translationTitle,
                translationDescription
            );
            setTranslationEdited(false);
        }

        if (questionEdited) {
            await superStore.editSubjectLanguageQuestion(
                subject.subject_id,
                subject.language,
                questionText,
                questionAnswer,
                questionWrong1,
                questionWrong2,
                questionWrong3
            );
            setQuestionEdited(false);
        }

        if (contentEdited) {
            await superStore.editSubjectLanguageContent(
                subject.subject_id,
                subject.language,
                value
            );
            setContentEdited(false);
        }
    };

    return !subject ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="subjectTitle"
                    id="subjectTitle"
                    value={translationTitle}
                    onChange={onChangeTranslationTitle}
                    variant="outlined"
                    label="Title"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="textarea"
                    multiline
                    name="description"
                    id="description"
                    value={translationDescription}
                    onChange={onChangeTranslationDescription}
                    variant="outlined"
                    label="Description"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="question"
                    id="question"
                    value={questionText}
                    onChange={onChangeQuestionText}
                    variant="outlined"
                    label="Question"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="answer"
                    id="answer"
                    value={questionAnswer}
                    onChange={onChangeQuestionAnswer}
                    variant="outlined"
                    label="Answer"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="wrong1"
                    id="wrong1"
                    value={questionWrong1}
                    onChange={onChangeQuestionWrong1}
                    variant="outlined"
                    label="Wrong 1"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="wrong2"
                    id="wrong2"
                    value={questionWrong2}
                    onChange={onChangeQuestionWrong2}
                    variant="outlined"
                    label="Wrong 2"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="wrong3"
                    id="wrong3"
                    value={questionWrong3}
                    onChange={onChangeQuestionWrong3}
                    variant="outlined"
                    label="Wrong 3"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Content</Typography>
                <HtmlField
                    initialValue={content && content.content}
                    fieldValue={content && content.content}
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={onSave}>
                    Save
                </Button>
            </Grid>
        </Grid>
    );
}

export default observer(SubjectLanguage);
